<template>
  <div class="wrap">
    <div class="wrap_box1">
      <el-input
        v-model.number="value1"
        placeholder="请输入删除简历的手机号"
      ></el-input>
      <el-button class="btn" size="mini" @click="del">删除</el-button>
    </div>
  </div>
</template>

<script>
import { delete_resume } from '@/api/hr24_api/api_all.js'
export default {
  data() {
    return {
      value1: '',
    }
  },
  methods: {
    del() {
      delete_resume(this.value1).then((res) => {
        if (!res.code) {
          this.value1 = ''
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        console.log(res)
      })
    },
  },
}
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.el-input {
  width: 30%;
  margin-right: 10px;
}
.wrap_box1 {
  display: flex;
}
</style>
